import {GameInfo} from '../types/config';

export const games:Array<GameInfo> = [
    {
        name: 'imagine',
        title: 'Поле чудес',
        path: '/imagine',
        imgCard: require('assets/img/game/card/game1.png'),
        imgBg: require('assets/img/game/bg/game1.png'),
        imgGameBg: require('assets/img/game/bg/imagine.png'),
        finishBg: require('assets/img/game/bg/finish.png'),
        rulesBg: 'radial-gradient(50% 50% at 50% 50%, #FFD600 0%, #FF8A00 100%)',
        isDisabled: false,
        colors: {
            buttons: {
                first: 'linear-gradient(180deg, #F7B203 0%, #FA4C01 100%)',
                second: 'linear-gradient(180deg, #CFF100 0%, rgba(26, 185, 0, 0.99) 100%)',
                third: 'linear-gradient(180deg, #FF3D00 0%, #FF0000 100%)',
                circleMode: [
                    'linear-gradient(180deg, #CFF100 0%, rgba(26, 185, 0, 0.99) 100%)',
                    'linear-gradient(180deg, #FF6B00 0%, #FF2E00 100%)'
                ]
            },
            users: [
                'linear-gradient(180deg, #FF6B00 0%, #FF2E00 100%)',
                'linear-gradient(180deg, #FFE600 0%, #FFA800 100%)',
                'linear-gradient(180deg, #CFF100 0%, rgba(26, 185, 0, 0.99) 100%)',
                'linear-gradient(180deg, #61D9FF 0%, rgba(0, 102, 255, 0.99) 100%)',
            ]
        },
        circleImg: require('assets/img/icons/circle1.png'),
        circleSize: 210,
        rules: `
        1. Выберите способ игры:<br> 
        С коробом<br>
        Внутри короба вы найдете стрелочку для игры. Соедините её с колесом на обратной стороне и крутите барабан<br>
        <br><br>
        Без короба<br> 
        Смотрите пункт 2.<br>  
        <br><br>
        2. Вас ждут три уровня сложности:<br>
        - Незнайка<br>
        - Знайка<br>
        - Всезнайка<br>
        Рекомендуем переходить от простого к сложному. Если вопрос кажется сложным для ребенка — поищите ответ всей семьей.
        <br><br>
        3. Введите имена всех участников игры. Количество игроков: 2-4.
        <br><br>
        4. Нажимайте кнопку “Играть” и переходите к игре.<br><br>
        Ход игры<br>
        1. Вращайте барабан. <br>
        <br>
        Что означают секторы на барабане:<br>
        - 0, 100, 200, 300, 400, 500, 600, 700, 800, 900 — количество очков, которое получает игрок в случае правильно угаданной буквы.<br> 
        - х2, х3 – текущий баланс игрока умножается на соответствующий множитель.<br>
        - Приз – игрок получает 1000 очков.<br>
        - Банкрот — игрок теряет все очки.<br>
        <br>
        2. На экране появляется вопрос и пустые ячейки для букв.<br>
        Игрокам предлагаются вопросы из следующих категорий: 
        - Животный мир<br>
        - Космос<br>
        - Кино и мультфильмы<br>
        - Города и страны<br>
        - Анатомия<br>
        И другие!<br> 
        Сложность вопросов зависит от уровня, выбранного ранее.<br>
        <br>
        3. Нажимайте кнопку «Назвать букву» — вам предложат угадать букву или ввести все слово целиком.<br> 
        — если буква угадана верно, то игрок зарабатывает очки и право хода остается за ним;<br>
        — если буква угадана неверно, то право хода переходит к  следующему игроку.<br>
        <br>
        Так игроки по очереди угадывают буквы и зарабатывают очки.<br>
        <br>
        4. Игрок, который правильно угадывает слово, получает 2000 очков.<br>
        За верно угаданное слово и выпадение полей х2, х3 игрок приумножает свой текущий баланс и получает 2000 очков сверху.<br>
        <br>
        Побеждает тот, у кого больше всего набранных очков.<br>
        Иии..вращайте барабан! Хорошей игры.
        `,
        circleFields: [
            {name: '200', angle: 11.25},
            {name: '100', angle: 33.75},
            {name: '900', angle: 56.25},
            {name: 'х2', angle: 78.75},
            {name: '600', angle: 101.25},
            {name: '800', angle: 123.75}, // красная стрелка
            {name: '300', angle: 146.25},
            {name: '400', angle: 168.75},
            {name: 'приз', angle: 191.25},
            {name: '0', angle: 213.75},
            {name: 'банкрот', angle: 236.25},
            {name: '500', angle: 258.75},
            {name: '300', angle: 281.25},
            {name: '200', angle: 303.75},
            {name: 'х3', angle: 326.25},
            {name: '700', angle: 348.75}
        ]
    },
    {
        name: 'drum',
        title: 'Фан Барабан',
        path: '/drum',
        imgCard: require('assets/img/game/card/game2.png'),
        imgBg: require('assets/img/game/bg/game2.png'),
        imgGameBg: require('assets/img/game/bg/drum.png'),
        finishBg: require('assets/img/game/bg/finishDrum.png'),
        rulesBg: 'radial-gradient(50% 50% at 50% 50%, #00FF38 0%, #06AC0C 100%)',
        isDisabled: false,
        colors: {
            buttons: {
                first: 'linear-gradient(180deg, #FF3D00 0%, #FF0000 100%)',
                second: 'linear-gradient(180deg, #CFF100 0%, rgba(26, 185, 0, 0.99) 100%)',
                third: 'linear-gradient(180deg, #FF3D00 0%, #FF0000 100%)',
                circleMode: [
                    'linear-gradient(180deg, #FF6B00 0%, #FF2E00 100%)',
                    'linear-gradient(180deg, #FFE600 0%, #FFA800 100%)'
                ]
            },
            users: [
                'linear-gradient(180deg, #F7B203 0%, #FA4C01 100%)',
                'linear-gradient(180deg, #CFF100 0%, rgba(26, 185, 0, 0.99) 100%)',
                'linear-gradient(180deg, #61D9FF 0%, rgba(0, 102, 255, 0.99) 100%)',
                'linear-gradient(180deg, #FFE600 0%, #FFA800 100%)'
            ]
        },
        circleImg: require('assets/img/icons/circle2.png'),
        circleSize: 250,
        rules: `
        1.Выберите способ игры:<br>
        С коробом<br>
        Внутри короба вы найдете стрелочку для игры. Соедините её с колесом на обратной стороне и крутите барабан.<br> 
        <br>
        Без короба<br> 
        Переходим к следующему пункту.<br>
        <br>
        2. Введите имена игроков. Количество участников: 2-4.<br>
        <br>
        3. Нажмите кнопку “Играть” и переходите к игре.<br>
        Важно: Если вы играете с коробом, то вам необходимо самостоятельно поставить стрелку в приложении на выпавший сектор, нажать кнопку “Играть” и выполнять задания.<br>
        <br>
        Ход игры<br>
        1. Вращайте барабан<br>
        Перед вами барабан, который поделен на 4 разноцветных сектора. Задания делятся на следующие категории:<br>
        - Покажи нечто (красный сектор)<br>
        - Физкультура (синий сектор)<br>
        - Прояви себя (зеленый сектор)<br>
        - Вопрос-ответ (желтый сектор)<br>
        <br>
        Подробнее о категориях.<br>
        - Покажи нечто: Игрок должен без слов показать нечто, что выпало ему в задании. Задача соперников — отгадать загаданное слово.<br>
        - Физкультура: Игрок должен выполнить простые физические упражнения в течение минуты.<br>
        - Прояви себя: Игрок выполняет юмористичные задания, которые направлены на раскрытие в том числе творческого потенциала.<br>
        - Вопрос-ответ: Игрок отвечает на вопрос общего кругозора. Проверить правильность ответа можно, нажав на кнопку “Показать ответ”.<br>
        За каждое выполненное действие игроки получают по 10 очков.<br>
        <br>
        Выигрывает тот игрок, который по итогу игры набрал наибольшее количество очков.<br>
        Веселой игры!<br>
        `,
        circleFields: [
            {name: 'physical', angle: 45},
            {name: 'show', angle: 135},
            {name: 'question-answer', angle: 225},
            {name: 'improve', angle: 315}
        ]
    },
    {
        name: 'quiz',
        title: 'IQ Викторина',
        path: '/quiz',
        imgCard: require('assets/img/game/card/game3.png'),
        imgBg: require('assets/img/game/bg/game3.png'),
        imgGameBg: require('assets/img/game/bg/quiz.png'),
        finishBg: require('assets/img/game/bg/finishQuiz.png'),
        rulesBg: 'radial-gradient(50% 50% at 50% 50%, #00C2FF 0%, #0038FF 100%)',
        isDisabled: false,
        colors: {
            buttons: {
                first: 'linear-gradient(180deg, #61D9FF 0%, rgba(0, 102, 255, 0.99) 100%)',
                second: 'linear-gradient(180deg, #CFF100 0%, rgba(26, 185, 0, 0.99) 100%)',
                third: 'linear-gradient(180deg, #CFF100 0%, rgba(26, 185, 0, 0.99) 100%)',
                circleMode: [
                    'linear-gradient(180deg, #AB18D0 0%, #FF007A 100%)',
                    'linear-gradient(180deg, #CFF100 0%, rgba(26, 185, 0, 0.99) 100%)'
                ]
            },
            users: [
                'linear-gradient(180deg, #61D9FF 0%, rgba(0, 102, 255, 0.99) 100%)',
                'linear-gradient(180deg, #AB18D0 0%, #FF007A 100%)',
                'linear-gradient(180deg, #FF6B00 0%, #FF2E00 100%)',
                'linear-gradient(180deg, #CFF100 0%, rgba(26, 185, 0, 0.99) 100%)'
            ]
        },
        circleImg: require('assets/img/icons/circle3.png'),
        circleSize: 250,
        rules: `
        1. Выберите способ игры:<br>
        С коробом<br>
        Внутри короба вы найдете стрелочку для игры. Соедините её с колесом на обратной стороне и крутите барабан.<br>
        <br>
        Без короба<br>
        Переходим к следующему пункту.<br>
        <br>
        2. Введите имена игроков. Количество участников 2-4 человека<br>
        <br>
        3. Нажмите кнопку “Играть” и переходите к игре<br>
        Важно: Если вы играете с коробом, то вам необходимо самостоятельно ставить стрелку в приложении на выпавший сектор, нажать кнопку “Играть” и выполнять задания.
        <br><br>
        4. Ход игры:<br>
        Вращайте барабан.<br>
        <br>
        Вам необходимо отвечать на вопрос из следующих категорий:<br>
        - Кино<br>
        - Игры<br>
        - Литература<br>
        - Животный мир<br>
        - Искусство<br>
        - Наука<br>
        - Спорт<br>
        - История<br>
        <br>
        На ответ отводится 30 секунд. Важно: ответ вводится в именительном падеже.<br> 
        По истечении времени на экране появляется правильный ответ.<br> 
        <br>
        За каждый правильный ответ Игрок получает 10 очков. Побеждает тот, кто наберет наибольшее количество баллов.<br>
        
        `,
        circleFields: [
            {name: 'literature', angle: 22.5},
            {name: 'games', angle: 67.5},
            {name: 'cinema', angle: 112.5},
            {name: 'history', angle: 157.5},
            {name: 'sport', angle: 202.5},
            {name: 'science', angle: 247.5},
            {name: 'art', angle: 292.5},
            {name: 'animals', angle: 337.5},
        ]
    }
];